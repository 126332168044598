import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { HeadProps, navigate } from 'gatsby';
import * as React from 'react';
import TopBar from '../../components/topbar/topbar';
import { verbs } from '../../data/conjugation';
import VerbPage from '../Verb/[verb]';
import { verbDropdown } from './reference.module.scss';

const options = verbs.map((v) => {
  return {
    id: v.dictionaryForm.korean,
    english: v.english,
    label: v.dictionaryForm.korean,
    romanized: v.dictionaryForm.pronunciationGuide,
    firstLetter: v.dictionaryForm.pronunciationGuide.charAt(0),
  };
});

const ReferencePage = (props) => {
  const selectedWord = options.find((f) => f.id === props.params.verb);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.label + ' ' + option.romanized + ' ' + option.english,
  });

  return (
    <div className="pageWithTopBar">
      <div className="topbar">
        <TopBar />
        <Autocomplete
          className={verbDropdown}
          disablePortal
          filterOptions={filterOptions}
          value={selectedWord}
          groupBy={(option) => option.firstLetter}
          options={options}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderInput={(params) => <TextField {...params} label="Word" />}
          renderOption={(props, option) => (
            <li {...props}>
              <div>
                <strong>{option.label}</strong>
              </div>
            </li>
          )}
          onChange={(event, value) => {
            if (value) navigate(`/Reference/${value.id}/`);
          }}
        />
      </div>

      <div className="page">{selectedWord ? <VerbPage verb={selectedWord.id} /> : null}</div>
    </div>
  );
};

export default ReferencePage;

export function Head(props: HeadProps) {
  return <title>Reference</title>;
}
